<template>
    <div class="identify-label" :style="`width:${size}px;height:${size}px`">
        <span v-if="userType == 2" class="identify-artist">
            <img src="@/assets/img/common/icon_artist.png"/>
        </span>
        <span v-if="userType == 3" class="identify-venue">
            <img src="@/assets/img/common/icon_venue.png"/>
        </span>
        <span v-if="userType == 5" class="identify-host">
            <img src="@/assets/img/common/icon_host.png"/>
        </span>
    </div>
</template>

<script>
export default {
    props:{
        userType:{
            type:[Number,String],
            default:2
        },
        size:{
            type:Number,
            default:16
        }
    }
}
</script>

<style lang="scss" scoped>
    .identify-label{
        border-radius: 100%;
        overflow: hidden;
        span{
            display: flex;
            align-content: center;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            &.identify-venue{
                background: linear-gradient(142deg, #11B08C 0%, #03D39E 100%);
            }
            &.identify-artist{
                background: linear-gradient(142deg, #EB484A 0%, #FF6124 100%);
            }
            &.identify-host{
                background: linear-gradient(142deg, #2438EF 0%,  #1B81FB 100%);
            }
        }
        img{
            max-width: 60%;
            max-height: 60%;
        }
    }
</style>