<template>
    <div class="container page-box-layout">
        <div class="search-box">
            <div class="tips">请先输入您的{{ userTypeName }}名称</div>
            <el-form @submit.native.prevent="search()">
                <div class="search-bar">
                    <el-input v-model="params.name" placeholder="输入名称,进行搜索">
                        <span slot="prefix" class="el-icon-search"></span>
                        <el-button slot="append" @click="search()">确认</el-button>
                    </el-input>
                </div>
            </el-form>
        </div>
        <div class="data-list-box" v-loading="dataLoading">
            <template v-if="data">
                <div v-if="dataList.length > 0" class="group-title">以下为与您名称相似的{{ userTypeName }}，如果就是您自己，请优先考虑进行认领</div>
                <ul>
                    <li v-for="(item,index) in dataList" :key="index" :class="item.isClaim == 1 || item.isClaim == 2 ? 'isClaim' : 'noClaim'" @click="claim(index)">
                        <div class="avatar">
                            <el-avatar :src="item.avatar" :size="56" icon="el-icon-user-solid"></el-avatar>
                            <identify-label :userType="item.userType" :size="20"></identify-label>
                        </div>
                        <div class="info">
                            <el-tooltip v-if="item.userType == 2" placement="top">
                                <div slot="content">名称：{{item.name}}<template v-if="item.styles"><br>风格：{{item.styles}}</template></div>
                                <div>
                                    <div class="name ellipsis">{{item.name}}</div>
                                    <div v-if="item.styles" class="desc ellipsis">#{{item.styles}}</div>
                                </div>
                            </el-tooltip>
                            <el-tooltip v-if="item.userType == 3" placement="top">
                                <div slot="content">名称：{{item.name}}<br>地址：{{item.address}}</div>
                                <div>
                                    <div class="name ellipsis">{{item.name}}</div>
                                    <div class="desc ellipsis">{{item.cities}}</div>
                                </div>
                            </el-tooltip>
                            <el-tooltip v-if="item.userType == 5" placement="top">
                                <div slot="content">名称：{{item.name}}<br>城市：{{item.cities}}</div>
                                <div>
                                    <div class="name ellipsis">{{item.name}}</div>
                                    <div class="desc ellipsis">{{item.cities}}</div>
                                </div>
                            </el-tooltip>
                        </div>
                        <div class="btn">我要认领</div>
                        <div v-if="item.isClaim==1" class="claim">已认领</div>
						<div v-if="item.isClaim==2" class="claim">认领中</div>
                    </li>
                </ul>
                <div v-if="!dataList || dataList.length < 1" class="message">没有发现与您名称相似的{{ userTypeName }}，请进行注册</div>
            </template>
        </div>
        <div v-if="data && dataList.length > 0" class="page-pagination-box">
            <el-pagination
                @size-change="handleSizeChange"
                @current-change="getDataList"
                :current-page.sync="params.pageNo"
                :page-sizes="[20, 50, 100]"
                :page-size.sync="params.pageSize"
                layout="sizes, prev, pager, next"
                :total="data.totalCount || 0">
            </el-pagination>
        </div>

        <div v-if="data" class="register-box">
            <!-- <p>这些都不是我,我需要注册一个新的账号</p> -->
            <div>
                <el-button type="primary" @click="goRegister">
                    <template v-if="dataList && dataList.length > 0">
                        这些都不是我，我需要注册一个新的账号
                    </template>
                    <template v-else>注册一个新的账号</template>
                </el-button>    
            </div>
        </div>

    </div>
</template>

<script>
import IdentifyLabel from '@/components/IdentifyLabel';
export default {
    components:{
        IdentifyLabel
    },
    data(){
        return{
            dataLoading: false,
            data:null,
            dataList:null,
            params:{
                name:'',
                pageNo:1,
                pageSize:20,
                userType: this.$route.query.userType || ''
            }
        }
    },
    computed: {
        userTypeName() {
            const _userType = this.params?.userType ? Number(this.params.userType) : ""
            return _userType? this.$userType.get(_userType) : "";
        }
    },
    methods:{
        search(){
            if(this.params.name){
                this.params.pageNo = 1; 
                this.getDataList();
            }
        },
        //获取列表（搜索结果）
        getDataList(){
            this.dataLoading = true;
            this.$request({
                url: "/user/register/getUserByName",
                data: this.params,
                method: "post",
            })
                .then((res) => {
                    const { state, msg, result } = res.data;
                    if (state == 1 && result) {
                        this.data = result;
                        this.dataList = result.result || [];
                    } else {
                        this.$message.error(msg || "获取数据失败,请重试");
                    }
                })
                .catch(() => {
                    this.$message.error("获取数据失败,请重试");
                })
                .finally(() => {
                    this.dataLoading = false;
                });
        },
        //选择pageSize
        handleSizeChange(e) {
            this.params.pageNo = 1;
            this.params.pageSize = e;
            this.getDataList();
        },
        //认领
        claim(index){
            const _item = this.dataList[index];
            if(!_item.isClaim || _item.isClaim == 0){
                this.$confirm('如果需要认领该账号，您需要提交一些信息以方便我们核实，是否开始认领该账号？', '认领', {
                    type: 'wraning',
                    confirmButtonText: '开始认领',
                    cancelButtonText:'再看看',
                    showClose:false,
                    customClass:'mpop',
                    closeOnClickModal: false
                }).then( () => {
                    this.$router.push({
                        path:'/register/step1',
                        query:{
                            userId:_item.id,
                            authType:1,
                            redirect: this.$router.currentRoute.fullPath
                        }
                    });
                }).catch(() => {});
            }else if( _item.isClaim == 2 ){
				this.$alert('当前账号已经被认领审核中！', '认领', {
				    type: 'wraning',
				    confirmButtonText: '知道了',
				    showCancelButton:false,
				    showClose:false,
				    customClass:'mpop',
				}).then( () => {
				    
				}).catch(() => {});
			}else{
                this.$alert('当前账号已经被认领，如果您确认这就是您，请联系秀动客服沟通核实', '认领', {
                    type: 'wraning',
                    confirmButtonText: '知道了',
                    showCancelButton:false,
                    showClose:false,
                    customClass:'mpop',
                }).then( () => {
                    
                }).catch(() => {});
            }
        },
        //全新注册
        goRegister(){
            this.$router.push({
                path:'/register/step1',
                query:{
                    userType:this.params.userType,
                    redirect: this.$router.currentRoute.fullPath,
                    authType:2
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
.search-box{
    text-align: center;
    margin-top: 40px;
    .tips{
        font-size: 22px;
    }
    .el-form{
        margin-top: 30px;
        .search-bar{
            display: inline-block;
            background: #f8f8f8;
            border-radius: 4px;
            .el-input{
                width: 620px;
                height: 48px;
                line-height: 48px;
                border:0;
                @media screen and (max-width: $--screen-md) {
                    width: auto;
                }
                ::v-deep input{
                    height: 48px; 
                    border-color: #f8f8f8;
                    background: none;
                    padding-left: 50px;
                }
                ::v-deep .el-input__prefix{
                    padding: 0 16px;
                    left: 0;
                }
                .el-icon-search{
                    font-size: 18px;
                    color: #000000;
                    font-weight: 700;
                }
                ::v-deep .el-input-group__append{
                    background-color:$--color-primary;
                    border-color: #f8f8f8;
                    color: #fff;
                }
            }
        }
        
    }
    
}

.data-list-box{
    margin: 48px 0 40px 0;
    .group-title{
        font-size: 16px;
    }
    ul{
        margin-top: 32px;
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        margin: 32px -8px;
        li{
            width: 235px;
            background: #f8f8f8;
            margin: 8px;
            border-radius: 4px;
            padding: 22px 18px;
            overflow: hidden;
            position: relative;
            cursor: pointer;
            @media screen and (max-width: $--screen-md) {
                width: calc(100% - 36px);
            }
            .avatar,.info{
                display: inline-block;
                vertical-align: middle;
            }
            .avatar{
                position: relative;
                .identify-label{
                    position: absolute;
                    right: 0;
                    bottom: 0;
                }
            }
            .info{
                margin-left: 18px;
                width: calc(100% - 56px - 18px);
                .desc{
                    margin-top: 5px;
                    color: $--color-info;
                }
            }
            .btn{
                background: rgba(255, 255, 255, 0.3);
                color: #ffffff;
                height: 18px;
                line-height: 18px;
                font-size: 12px;
                text-align: center;
                position: absolute;
                width: 100%;
                left: 0;
                bottom: 0;
                display: none;
            }
            &.noClaim:hover{
                background: $--color-theme;
                .info,.desc{
                    color: #ffffff;
                }
                .btn{
                    display: block;
                }
            }
            &.isClaim{
                // cursor: not-allowed;
                background: rgba(248, 248, 248, 0.3);
                .el-avatar,.info{
                    opacity: 0.3;
                }
                .claim{
                    position: absolute;
                    right: 0;
                    top: 0;
                    height: 15px;
                    line-height: 15px;
                    background: #EBEBEB;
                    border-radius: 0px 0 0px 7px;
                    padding: 0 8px;
                }
            }
        }
    }
}

.register-box{
    margin:  40px 0;
    p{
        font-size: 16px;
    }
}
</style>